import { useState } from 'react'

import { DateTime } from 'luxon'
import Image from 'next/image'
import Link from 'next/link'
import { useSession } from 'next-auth/react'

import { withSession } from '@tootz/react-admin'

import { BookmarkFilled, RecentlyViewed } from '@carbon/icons-react'

import { BRAND_NAME } from '../../../react-admin.config'
import { useAppContext } from '../App/context'
import AuthModal from '../Auth/Modal'
import SearchInput from '../Search/Input'

import { Footer } from '@/components/Layout/Footer'
import Tabs from '@/components/Tabs'
import { useSubscriptionContext } from '@/context/subscription'
import ShortcutList from '@/domain/Documents/ShortcutList'
import logo from '@/public/logo.png'

import { TipsCarousel } from './TipsCarousel'

const Home = () => {
  const [showAuthModal, setShowAuthModal] = useState(false)
  const { visitedDocuments, savedDocumentsIds } = useAppContext()
  const maintenanceMode = DateTime.now().hour >= 0 && DateTime.now().hour <= 5
  const handleChange = tabSlug => setActiveTab(tabSlug)
  const { status } = useSession()
  const isAuthenticated = status === 'authenticated'
  const visitedDocumentsTab = {
    slug: 'recentDocuments',
    name: 'Lidos Recentemente',
    icon: <RecentlyViewed />
  }
  const savedDocumentsTab = {
    slug: 'savedDocuments',
    name: 'Salvos em coleções',
    icon: <BookmarkFilled />
  }

  const homeTabs = [
    Object.keys(visitedDocuments).length != 0 && visitedDocumentsTab,
    savedDocumentsIds.length != 0 && savedDocumentsTab
  ].filter(item => !!item)
  const [activeTab, setActiveTab] = useState(_.get(homeTabs, '0.slug'))

  const { subscription, expired, isTrial } = useSubscriptionContext()

  return (
    <div className="vh-100 vw-100 bg-primary py-8">
      <div className="w-100 d-flex align-items-center justify-content-center position-absolute top-0 start-0 py-8 z-index-1">
        <nav className="container d-flex flex-row align-items-center justify-content-end position-relative">
          {/* <Link href="/" className="text-white fs-6 mx-4 text-end">
            Página Inicial
          </Link> */}
          {/* <Link
            href="http://site.maestrialaw.com.br"
            target="blank"
            className="text-white fs-6 position-absolute start-50 translate-middle-lg-x"
          >
            Sobre
          </Link> */}
          {!isAuthenticated && (
            <>
              <button
                className="btn btn-primary text-white cursor-pointer fs-6 me-5"
                onClick={() => setShowAuthModal('sign-in')}
              >
                Entrar
              </button>
              <button
                className="btn btn-dark text-white fs-6"
                onClick={() => setShowAuthModal('sign-up')}
              >
                Cadastre-se
              </button>
            </>
          )}
          {/* <Link href="/colecoes" className="text-white fs-6 mx-4">
            Coleções
          </Link> */}
          {isAuthenticated && (
            <>
              {(!isTrial || (!!subscription && expired)) && (
                <Link href="/assinatura/checkout" className="btn btn-dark">
                  Assine já
                </Link>
              )}
              <Link href="/minha-conta" className="text-white fs-6 mx-4">
                Minha Conta
              </Link>
              <Link href="/auth/sign-out" className="text-white fs-6 mx-4">
                Sair
              </Link>
            </>
          )}
        </nav>
      </div>
      <div className="container h-100 d-flex flex-column align-items-center">
        <div className="my-auto  d-flex flex-column align-items-center w-100">
          <div className="w-100 mw-350px mb-10">
            <Image
              src={logo}
              alt={BRAND_NAME}
              // placeholder="blur"
              className="h-auto w-100 d-block ps-5"
              priority
            />
          </div>
          {maintenanceMode ? (
            <div className="d-flex flex-column mw-550px text-center text-white">
              <span className="fs-2 fw-bold text-uppercase mb-3 text-white d-block">
                Plataforma em manutenção
              </span>
              <span className="d-block mb-2">
                Buscando uma melhor experiência e informações cada vez mais
                relevantes, estamos efetuando uma manutenção na nossa plataforma
              </span>
              <span className="d-block">
                O sistema retornará ao ar às 6h (UTC-3)
              </span>
            </div>
          ) : (
            <>
              <div className="mw-600px w-100">
                <SearchInput blue home />
                <TipsCarousel />
              </div>
              {isAuthenticated && (
                <>
                  <Tabs
                    active={activeTab}
                    onChange={tabSlug => handleChange(tabSlug)}
                    tabs={homeTabs}
                    className="mt-16"
                  />
                  <ShortcutList active={activeTab} blue />
                </>
              )}
            </>
          )}
        </div>
      </div>
      {showAuthModal && (
        <AuthModal
          initialAction={showAuthModal}
          onHide={() => setShowAuthModal(false)}
        />
      )}
      <Footer />
    </div>
  )
}

export default withSession(Home)
